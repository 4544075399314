import { render, staticRenderFns } from "./LedgerRow.vue?vue&type=template&id=bc0efc32&scoped=true"
import script from "./LedgerRow.vue?vue&type=script&lang=js"
export * from "./LedgerRow.vue?vue&type=script&lang=js"
import style0 from "./LedgerRow.vue?vue&type=style&index=0&id=bc0efc32&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc0efc32",
  null
  
)

export default component.exports
import { render, staticRenderFns } from "./PasswordResets.vue?vue&type=template&id=40c89d44&scoped=true"
import script from "./PasswordResets.vue?vue&type=script&lang=js"
export * from "./PasswordResets.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40c89d44",
  null
  
)

export default component.exports
import { render, staticRenderFns } from "./ViewTitle.vue?vue&type=template&id=2da6e4c3&scoped=true"
import script from "./ViewTitle.vue?vue&type=script&lang=js"
export * from "./ViewTitle.vue?vue&type=script&lang=js"
import style0 from "./ViewTitle.vue?vue&type=style&index=0&id=2da6e4c3&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2da6e4c3",
  null
  
)

export default component.exports
<template>
<div>
  <p>
      The game has started. Good luck and have fun!
  </p>
</div>
</template>

<script>
export default {
  components: {

  },
  props: {
    event: Object
  },
  methods: {

  }
}
</script>

<style scoped>
</style>

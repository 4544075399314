import { render, staticRenderFns } from "./CombatCalculator.vue?vue&type=template&id=2965cade&scoped=true"
import script from "./CombatCalculator.vue?vue&type=script&lang=js"
export * from "./CombatCalculator.vue?vue&type=script&lang=js"
import style0 from "./CombatCalculator.vue?vue&type=style&index=0&id=2965cade&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2965cade",
  null
  
)

export default component.exports
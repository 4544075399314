import { render, staticRenderFns } from "./InfrastructureUpgrade.vue?vue&type=template&id=a12d9b90&scoped=true"
import script from "./InfrastructureUpgrade.vue?vue&type=script&lang=js"
export * from "./InfrastructureUpgrade.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a12d9b90",
  null
  
)

export default component.exports
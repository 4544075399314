import { render, staticRenderFns } from "./UserGuildInfo.vue?vue&type=template&id=4572baf6&scoped=true"
import script from "./UserGuildInfo.vue?vue&type=script&lang=js"
export * from "./UserGuildInfo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4572baf6",
  null
  
)

export default component.exports
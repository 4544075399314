import { render, staticRenderFns } from "./EloRating.vue?vue&type=template&id=88b89c1c&scoped=true"
import script from "./EloRating.vue?vue&type=script&lang=js"
export * from "./EloRating.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88b89c1c",
  null
  
)

export default component.exports
import { render, staticRenderFns } from "./GameNotes.vue?vue&type=template&id=1ec50cc6&scoped=true"
import script from "./GameNotes.vue?vue&type=script&lang=js"
export * from "./GameNotes.vue?vue&type=script&lang=js"
import style0 from "./GameNotes.vue?vue&type=style&index=0&id=1ec50cc6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ec50cc6",
  null
  
)

export default component.exports
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"content"}},[(!_vm.hideTopBar)?_c('view-container-top-bar'):_vm._e(),_c('div',{staticClass:"container pb-3 col-xs-12 col-sm-10 col-md-10 col-lg-6"},[_vm._t("default"),_c('footer',{staticClass:"mt-3"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('router-link',{staticClass:"me-2",attrs:{"to":{ name: 'privacy-policy'},"title":"Privacy Policy"}},[_c('i',{staticClass:"fas fa-file-alt"})]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3)],1),_c('div',{staticClass:"col-auto"},[_c('router-link',{staticClass:"text-success",attrs:{"to":{ name: 'galactic-credits-shop'}}},[_c('i',{staticClass:"fas fa-shopping-basket me-1"}),_vm._v("Shop")]),_vm._v(" | "),_vm._m(4),_vm._v(" | "),_vm._m(5)],1)])])],2),_c('div',{staticClass:"mb-3 d-none d-md-block"})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"me-2",attrs:{"href":"https://github.com/solaris-games/solaris","target":"_blank","title":"Code"}},[_c('i',{staticClass:"fab fa-github"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"me-2",attrs:{"href":"https://store.steampowered.com/app/1623930/Solaris/","target":"_blank","title":"Steam"}},[_c('i',{staticClass:"fab fa-steam"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"me-2",attrs:{"href":"https://discord.com/invite/v7PD33d","target":"_blank","title":"Discord"}},[_c('i',{staticClass:"fab fa-discord"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"me-2",attrs:{"href":"https://steamcommunity.com/app/1623930/discussions/","target":"_blank","title":"Forum"}},[_c('i',{staticClass:"far fa-comments"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"text-info",attrs:{"href":"https://www.redbubble.com/shop/ap/82527983","target":"_blank"}},[_c('i',{staticClass:"fas fa-tshirt me-1"}),_vm._v("Swag")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"text-warning",attrs:{"href":"https://www.buymeacoffee.com/limitingfactor","target":"_blank"}},[_c('i',{staticClass:"fas fa-coffee me-1"}),_vm._v("Donate")])
}]

export { render, staticRenderFns }
import { render, staticRenderFns } from "./OptionsForm.vue?vue&type=template&id=796ddcd8&scoped=true"
import script from "./OptionsForm.vue?vue&type=script&lang=js"
export * from "./OptionsForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "796ddcd8",
  null
  
)

export default component.exports
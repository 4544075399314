import { render, staticRenderFns } from "./Research.vue?vue&type=template&id=01c07f6a&scoped=true"
import script from "./Research.vue?vue&type=script&lang=js"
export * from "./Research.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01c07f6a",
  null
  
)

export default component.exports
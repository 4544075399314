import { render, staticRenderFns } from "./GamePlayerQuit.vue?vue&type=template&id=cde75e22&scoped=true"
import script from "./GamePlayerQuit.vue?vue&type=script&lang=js"
export * from "./GamePlayerQuit.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cde75e22",
  null
  
)

export default component.exports
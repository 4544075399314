import { render, staticRenderFns } from "./ConversationDetail.vue?vue&type=template&id=20f9d032&scoped=true"
import script from "./ConversationDetail.vue?vue&type=script&lang=js"
export * from "./ConversationDetail.vue?vue&type=script&lang=js"
import style0 from "./ConversationDetail.vue?vue&type=style&index=0&id=20f9d032&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20f9d032",
  null
  
)

export default component.exports
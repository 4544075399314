<template>
<div class="row bg-dark mb-3">
  <div class="col">
  <h4 class="mt-3 mb-3">{{ title }}</h4>
  </div>
</div>
</template>

<script>
export default {
  props: {
    title: String
  }
}
</script>
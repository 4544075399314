import { render, staticRenderFns } from "./SidebarMenuItem.vue?vue&type=template&id=9d44951c&scoped=true"
import script from "./SidebarMenuItem.vue?vue&type=script&lang=js"
export * from "./SidebarMenuItem.vue?vue&type=script&lang=js"
import style0 from "./SidebarMenuItem.vue?vue&type=style&index=0&id=9d44951c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9d44951c",
  null
  
)

export default component.exports
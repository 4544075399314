export default {
  NONE: null,
  WELCOME: 'welcome',
  TUTORIAL: 'tutorial',
  LEADERBOARD: 'leaderboard',
  RESEARCH: 'research',
  GALAXY: 'galaxy',
  INTEL: 'intel',
  OPTIONS: 'options',
  SETTINGS: 'settings',
  HELP: 'help',
  INBOX: 'inbox',
  EVENT_LOG: 'eventLog',
  CONVERSATION: 'conversation',
  COMBAT_CALCULATOR: 'combatCalculator',
  NEW_MESSAGE: 'newMessage',
  MESSAGE: 'message',
  PLAYER: 'player',
  TRADE: 'trade',
  SEND_TECHNOLOGY: 'sendTechnology',
  SEND_MONEY: 'sendMoney',
  STAR: 'star',
  STAR_DETAIL: 'starDetail',
  STAR_RENAME: 'starRename',
  BUILD_CARRIER: 'buildCarrier',
  CARRIER: 'carrier',
  CARRIER_DETAIL: 'carrierDetail',
  CARRIER_WAYPOINTS: 'carrierWaypoints',
  CARRIER_WAYPOINT_DETAIL: 'carrierWaypointDetail',
  CARRIER_RENAME: 'carrierRename',
  SHIP_TRANSFER: 'shipTransfer',
  BULK_INFRASTRUCTURE_UPGRADE: 'bulkInfrastructureUpgrade',
  MAP_OBJECT_SELECTOR: 'mapObjectSelector',
  RULER: 'ruler',
  LEDGER: 'ledger',
  DIPLOMACY: 'diplomacy',
  HIRE_SPECIALIST_CARRIER: 'hireSpecialistCarrier',
  HIRE_SPECIALIST_STAR: 'hireSpecialistStar',
  GAME_NOTES: 'gameNotes',
  CREATE_CONVERSATION: 'createConversation',
  PLAYER_BADGE_SHOP: 'playerBadgeShop',
  REPORT_PLAYER: 'reportPlayer',
  SPECTATORS: 'spectators'
}
